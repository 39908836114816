/* Global Styles */
$primary-color : #00153B;

$secondary-color : #940523;

$tertiary-color : #FFEFE3;

html, body {
    margin: 0;
    padding: 0;
    font-family: jubilat, serif;
    font-style: normal;
    color: $primary-color;
}
#root {
  width: 100%;
  height: 100%;
}
body {
  background-color: #FFFFFF;
}
ul {
  list-style: none;
  padding-inline-start: 0;
}
li {
  font-weight: 00;
}
footer {
  background-color: $tertiary-color;
  height: 8em;
}
nav {
  margin-top: 10px;
}
header {
  padding-top: 10px;
  background-color: #FFFFFF;
}
a {
  color: $primary-color;
  font-weight: 400;
}
p {
  font-weight: 300;
}
span {
  color: $secondary-color;
  text-decoration: underline;
}
h2 {
  margin-left: .5em;
  margin-right: .5em;
}
h3 {
  margin-right: .5em;
  margin-left: -.1em;
}
h4 {
  margin-left: .5em;
  margin-right: .5em;
}
.clearer {
  height: 80px;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.subtitle {
  margin-top: .5em;
}
.line {
  height: 2px;
  background-color: $primary-color;
  margin: 0 0 .5em 0;
  width: 100%;
}
.title-morethanone {
  white-space: nowrap;
}
.title-services-left {
  margin-right: .75em;
  margin-left: 0;
}
.title-services-right {
  margin-left: .75em;
  margin-right: 0;
}
.row {
  height: 100%;
}
.menu-specials-highlight {
  color: $primary-color;
  text-decoration: none;
  font-style: italic;
}
.services-row {
  margin-bottom: 1.5em;
}
.services-img {
  margin-top: .5em;
}
.services-pricing {
  font-style: italic;
}
.header-nav li {
  display: inline;
}
.cls-1 {
  fill: #00153b;
}
.cls-2 {
  fill: #940523;
}
.title-container {
  margin-bottom: .5em;
}
.info-wrapper {
  margin: 0;
}
.info-container p{
  margin: 0;
}
.info-container a{
  font-weight: 300;
  font-style: italic;
}
.footer-nav-one ul{
  margin: 0 0 .25em 0;
}
.footer-nav-two ul{
  margin: 0 0 .25em 0;
}
.social-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.social-container a {
  margin-left: 20%;
}
.main-btn {
  margin-top: 1em;
  margin-bottom: .5em;
}
.main-btn a {
  text-decoration: none;
}
.main-btn img {
  width: 100%;
  margin-bottom: .5em;
}
.main-btn:hover div.line {
  background-color: #0a58ca;
}
.menu-item-price {
  color: $primary-color;
  text-decoration: none;
  font-weight: 500;
}
.menu-item-amends {
  font-style: italic;
}
.bookings-form-container {
  height: 5em;
}
.contact-maps-container {
  margin-top: 2.5%;
}
.form-group {
  margin-bottom: 1em;
}
.full-width-img {
  width: 100%;
}
.btn-primary {
  background-color: $primary-color;
  border: none;
}
.hero-image {
  width: 100%;
  height: 100%;
}
.media-object-title {
  margin-top: -.2em;
}
.media-object-img {
  margin-bottom: 2em;
}
.media-object-text {
  margin-bottom: 2em;
}
.title-learnmore {
  margin-left: .5em;
  margin-right: .5em;
}

// Hamburger Menu Button, close Button and Sidebar
#sidebar-menu {
  position: fixed;
  background-color: $tertiary-color;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 40%;
  min-width: 200px;
  height: calc(100% - 95px);
}
#sidebar-menu ul li {
  margin: .2em 0 .2em 0;
  padding: .75em 0 .75em 1.5em;
  width: 100%;
}
// Removes the default button styling
#closeButton {
  border: none;
  background-color: #FFFFFF;
}
#openButton {
  border: none;
  background-color: #FFFFFF;
}
// Translucent background behind the sidebar menu
.blocker {
  position: fixed;
  background-color: $primary-color;
  z-index: 999;
  opacity: 50%;
  width: 90%;
  height: calc(100% - 95px);
  right: 0;
  bottom: 0;
}
// Shows the sidebar button when active
.nav-show {
  display: block;
}
// Removes the sidebar button when active
.nav-hide {
  display: none;
}
// Changes the background colour of the sidebar link when it's page is active
a.active li {
  background-color: #FFFFFF;
}
.active {
  text-decoration: none;
  color: $secondary-color;
}
@media (max-width: 767px) {
  .mobile-title-container {
    top: -5px;
    left: 0;
    background-color: #FFFFFF;
    height: 100px;
  }
  
}